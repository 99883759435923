import React from 'react';
import Img from 'gatsby-image';
import ReactMarkdown from 'react-markdown';
import {
    BasicDoughnut,
    LongArch,
    BasicCircle
} from '../../../../SVGComponents/shapes';
import HowItWorksShapesSection from './shapes/shapes';

import styles from './HowItWorks.module.scss';

const HowItWorks = ({ image, steps, howItWorksTitle }) => {
    return (
        <section className={styles.HowItWorksContainer}>
            <BasicDoughnut
                color="mercury"
                className={styles.BasicMercuryDoughnut}
                sm="sm"
            />
            <LongArch
                rotate="right"
                className={styles.LongCoralArch}
                color="coral"
            />
            <BasicCircle
                color="denim10"
                className={styles.BasicDenimCircle}
                size="sm"
            />
            <HowItWorksShapesSection>
                <div className={styles.HowItWorks}>
                    <h2>{howItWorksTitle}</h2>
                    <hr />
                    <div className={styles.container}>
                        {image && (
                            <div className={styles.image}>
                                <Img fluid={image.fluid} />
                            </div>
                        )}
                        <div className={styles.steps}>
                            {steps &&
                                steps.map((step, i) => (
                                    <div className={styles.step} key={i}>
                                        <div className={styles.number}>
                                            {i + 1}
                                            <div className={styles.line}></div>
                                        </div>
                                        <div className={styles.info}>
                                            <h3>{step.title}</h3>
                                            <ReactMarkdown
                                                className={styles.description}
                                                source={
                                                    step.description.description
                                                }
                                            />
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            </HowItWorksShapesSection>
        </section>
    );
};

export default HowItWorks;
