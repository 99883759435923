import React from 'react';
import Img from 'gatsby-image';

import styles from './Quote.module.scss';

const Quote = ({ image, quote, mobileQuote, author }) => {
    return (
        <section>
            <div className={styles.Quote}>
                <div className={styles.imageWrapper}>
                    <Img fluid={image.fluid} />
                </div>
                <h3 className={styles.quote}>“{quote}”</h3>
                {mobileQuote && (
                    <h3 className={styles.mobileQuote}>“{mobileQuote}”</h3>
                )}
                <p>{author}</p>
            </div>
        </section>
    );
};

export default Quote;
