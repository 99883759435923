import React from 'react';
import { graphql } from 'gatsby';
import lodashGet from 'lodash/get';
import MetaData from '../../../components/Layout/MetaData.jsx';
import NavBar from '../../../components/NavBar/NavBar';
import HowItWorks from './HowItWorks/HowItWorks';
import Header from './Header/Header';
import Features from './Features/Features';
import Quote from './Quote/Quote';
import CalculatorSection from '../common/CalculatorSection/CalculatorSection';
import WantToKnowMore from '../common/WantToKnowMore/WantToKnowMore';
import ReadAboutUs from '../common/ReadAboutUs/ReadAboutUs';
import Questions from '../common/Questions/Questions';
import SimpleFooter from '../../../components/Footer/SimpleFooter/SimpleFooter';
import styles from './GenericLanding.module.scss';
import { MAX_LOAN_SIZE } from 'aphrodite-shared/util/iwocaConstants';

const GenericLanding = ({ data }) => {
    const { contentfulPageGenericLanding: pageData, trustpilot } = data;
    const {
        title,
        metaDescription,
        allowSearchEngineScraping,
        url,
        genericLandingSections: sections,
        genericLandingHeader: header,
        productType
    } = pageData;

    return (
        <div className={styles.GenericLanding}>
            <MetaData
                metaData={{
                    titleTag: title,
                    metaDescription,
                    allowSearchEngineScraping,
                    url
                }}
            />
            <NavBar
                isLandingPage={pageData.useFullNavbar ? false : true}
                partnershipLogo={
                    pageData.useFullNavbar ? null : pageData.partnershipLogo
                }
                phoneNumber={lodashGet(
                    pageData,
                    'phoneNumber.phoneDisplayNumber'
                )}
                phoneNumberLink={lodashGet(pageData, 'phoneNumber.callNumber')}
                maximumLoanAmount={pageData.maximumLoanAmount}
            />
            {header && (
                <Header
                    title={header.genericHeaderTitle}
                    subtitle={
                        header.genericHeaderSubtitle &&
                        header.genericHeaderSubtitle.genericHeaderSubtitle
                    }
                    buttons={header.genericLandingCtas}
                    image={header.genericHeaderImage}
                    circularHeaderImage={header.genericCircularHeaderImage}
                    trustpilotRating={trustpilot.score.trustScore}
                    trustpilotReviews={trustpilot.numberOfReviews.total}
                    disclaimerText={header.genericHeaderDisclaimerText}
                    partnershipLogo={header.genericHeaderPartnershipLogo}
                />
            )}
            {sections &&
                sections.map((section) => {
                    switch (section.__typename) {
                        case 'ContentfulSectionLandingPageFeatures':
                            return (
                                <Features
                                    title={section.featureTitle}
                                    featureText={
                                        section.featureText &&
                                        section.featureText.featureText
                                    }
                                    mobileTitle={section.featureMobileTitle}
                                    features={section.features}
                                />
                            );
                        case 'ContentfulSectionSingleQuote':
                            return (
                                <Quote
                                    image={section.quoteImage}
                                    quote={section.quoteText}
                                    mobileQuote={section.quoteTextMobile}
                                    author={section.quoteAuthor}
                                />
                            );
                        case 'ContentfulSectionLandingPageHowItWorks':
                            return (
                                <HowItWorks
                                    howItWorksTitle={section.howItWorksTitle}
                                    image={section.howItWorksImage}
                                    steps={section.howItWorksSteps}
                                />
                            );
                        case 'ContentfulNewRepaymentCalculator':
                            return (
                                <CalculatorSection
                                    calculatorCopy={section}
                                    showArrow={false}
                                    overflowShapes={{
                                        WedgedArchShape: false,
                                        LongArchShape: false,
                                        SectionArchShape: false
                                    }}
                                    maximumLoanAmount={MAX_LOAN_SIZE.en}
                                />
                            );
                        case 'ContentfulSectionFaQs':
                            return (
                                <WantToKnowMore
                                    faq={section.faqs}
                                    title={section.title}
                                    subtitle={section.faqSubtitle.subtitle}
                                />
                            );
                        case 'ContentfulSectionLandingReadAboutIwoca':
                            return (
                                <ReadAboutUs
                                    desktopTitle={
                                        section.readAboutUsDesktopTitle
                                    }
                                    mobileTitle={section.readAboutUsMobileTitle}
                                    trustpilotRating={
                                        trustpilot.score.trustScore
                                    }
                                    pressReleaseIcons={
                                        section.pressReleaseSection.pressPhotos
                                    }
                                    trustpilotText={
                                        section.pressReleaseSection
                                            .trustpilotText
                                    }
                                    displayBbbLogo={
                                        productType &&
                                        productType[0] &&
                                        productType[0] == 'rls'
                                    }
                                />
                            );
                        case 'ContentfulContactUsQuestions':
                            return (
                                <Questions
                                    title={section.title}
                                    content={section.content.content}
                                    email={section.email}
                                    phoneNumber={section.phoneNumber}
                                    showApplyButton={section.showApplyButton}
                                    showJobsButton={section.showJobsButton}
                                    jobCTAText={pageData.jobCtaText}
                                    jobLink={pageData.jobLink}
                                    loanCTAText={pageData.loanCtaText}
                                    hideHelpModal={true}
                                />
                            );
                        default:
                            return null;
                    }
                })}

            <SimpleFooter />
        </div>
    );
};

export default GenericLanding;

export const query = graphql`
    query GenericLandingQuery($id: String!) {
        contentfulPageGenericLanding(id: { eq: $id }) {
            title
            url
            circularHeaderImage
            useFullNavbar
            phoneNumber {
                phoneDisplayNumber
                callNumber
            }
            partnershipLogo {
                fluid(maxWidth: 688, quality: 100) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                }
            }
            metaDescription
            allowSearchEngineScraping
            headerTitle
            headerSubtitle {
                headerSubtitle
            }
            headerButton
            headerDisclaimerText {
                headerDisclaimerText
            }
            headerImage {
                fluid(maxWidth: 688, quality: 100) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                }
            }

            genericLandingHeader {
                ... on ContentfulHeaderGenericLandingPage {
                    genericHeaderTitle
                    genericHeaderSubtitle {
                        genericHeaderSubtitle
                    }
                    genericHeaderImage {
                        fluid(maxWidth: 688, quality: 100) {
                            ...GatsbyContentfulFluid_withWebp_noBase64
                        }
                    }
                    genericCircularHeaderImage
                    genericHeaderDisclaimerText
                    genericHeaderPartnershipLogo {
                        fluid(maxWidth: 688, quality: 100) {
                            ...GatsbyContentfulFluid_withWebp_noBase64
                        }
                    }
                    genericLandingCtas {
                        ... on ContentfulButton {
                            ...Button
                        }
                    }
                }
            }

            productType

            genericLandingSections {
                __typename
                ... on Node {
                    ... on ContentfulSectionLandingPageFeatures {
                        featureTitle
                        featureMobileTitle
                        featureText {
                            featureText
                        }
                        features {
                            id
                            featureTitle {
                                featureTitle
                            }
                            description {
                                description
                            }
                            icon {
                                id
                                fluid(maxWidth: 75, quality: 100) {
                                    ...GatsbyContentfulFluid_withWebp_noBase64
                                }
                            }
                        }
                    }
                    ... on ContentfulSectionSingleQuote {
                        quoteImage {
                            fluid(maxWidth: 688, quality: 100) {
                                ...GatsbyContentfulFluid_withWebp_noBase64
                            }
                        }
                        quoteText
                        quoteTextMobile
                        quoteAuthor
                    }
                    ... on ContentfulSectionLandingPageHowItWorks {
                        howItWorksTitle
                        howItWorksImage {
                            fluid(maxWidth: 688, quality: 100) {
                                ...GatsbyContentfulFluid_withWebp_noBase64
                            }
                        }
                        howItWorksSteps {
                            ...HowItWorksStep
                        }
                    }

                    ... on ContentfulSectionLandingReadAboutIwoca {
                        readAboutUsDesktopTitle
                        readAboutUsMobileTitle
                        pressReleaseSection {
                            ...pressReleaseSection
                        }
                    }
                    ... on ContentfulSectionFaQs {
                        title
                        faqSubtitle: subtitle {
                            subtitle
                        }
                        faqs {
                            question
                            answer
                            color
                            longAnswer {
                                longAnswer
                            }
                        }
                    }
                    ... on ContentfulNewRepaymentCalculator {
                        borrowUpToText
                        title
                        mobileTitle
                        totalRepaymentText
                        subtitle
                        equalRepaymentText
                        forText
                        helpText
                        repaymentText
                        footerText {
                            footerText
                        }
                        ctaButton {
                            buttonText
                        }
                        interestText
                    }

                    ... on ContentfulContactUsQuestions {
                        ...contactUsSection
                    }
                }
            }

            trustpilot

            contactUsSection {
                ...contactUsSection
            }
            maximumLoanAmount: maxLoanSize
        }
        trustpilot {
            ...trustPilot
        }
    }
`;
