import React from 'react';
import classnames from 'classnames';
import ReactMarkdown from 'react-markdown';
import { FormattedMessage } from 'react-intl';
import Img from 'gatsby-image';
import Button from 'aphrodite-shared/ui/Button/Button';
import TrustPilotLogoInline from '../../../../SVGComponents/TrustPilotLogoInline';
import useQueryUrl from '../../../../util/useQueryUrl.hook';
import { Amplitude, AmplitudeWebsite } from '@iwoca/frontend-tracking-library';
import styles from './Header.module.scss';
import GatsbyImage from 'gatsby-image';
import { useProductName } from '../../../../components/IwModals/StepZeroModal/StepZeroModalHelpers';

const trackHeroBannerCta = (ctaText, productName) => {
    const getStartedCtaEvent = AmplitudeWebsite.getStep0ModalEventWithProductTypeAndText(
        AmplitudeWebsite.OPEN_HERO_BANNER_STEP_0_MODAL,
        ctaText,
        productName
    );
    Amplitude.track(getStartedCtaEvent);
};

const Header = ({
    title,
    subtitle,
    buttons,
    image,
    trustpilotRating,
    trustpilotReviews,
    disclaimerText,
    circularHeaderImage,
    partnershipLogo
}) => {
    const productName = useProductName();
    const circularImageStyle = () => {
        if (circularHeaderImage || circularHeaderImage === null) {
            return styles.circularImage;
        }
    };

    return (
        <section>
            <div className={styles.Header}>
                {partnershipLogo && (
                    <>
                        <div className={styles.partnershipText}>
                            In partnership with
                        </div>
                        <GatsbyImage
                            className={styles.PartnershipLogo}
                            fluid={partnershipLogo.fluid}
                            imgStyle={{ objectFit: 'scale-down' }}
                        />
                    </>
                )}
                <div className={styles.info}>
                    <h1>{title}</h1>
                    {subtitle && <ReactMarkdown source={subtitle} />}

                    <div className={styles.buttons}>
                        {buttons &&
                            buttons.map((button) => {
                                const { queryUrl } = useQueryUrl(button.url);

                                return (
                                    <Button
                                        onClick={() => {
                                            trackHeroBannerCta(
                                                button.buttonText,
                                                productName
                                            );
                                        }}
                                        className={styles.buttonWrapper}
                                        buttonClass={styles.Button}
                                        colour={button.buttonColour}
                                        href={queryUrl}
                                        title={button.buttonText}
                                        dataGaId={button.dataGaId}
                                        isDownloadable={button.isDownloadable}>
                                        {button.buttonText}
                                    </Button>
                                );
                            })}
                    </div>

                    {disclaimerText && (
                        <ReactMarkdown
                            source={disclaimerText}
                            className={styles.DisclaimerText}
                        />
                    )}
                    <hr />
                </div>
                <div className={classnames(styles.image, circularImageStyle())}>
                    <Img fluid={image.fluid} />
                </div>
            </div>
            <div className={styles.trustElements}>
                <a
                    className={styles.trustpilot}
                    href="https://uk.trustpilot.com/review/iwoca.co.uk"
                    target="_blank"
                    rel="noopener noreferrer">
                    <TrustPilotLogoInline />
                    <p className={styles.trustpilotInfo}>
                        <FormattedMessage
                            id="trustPilot"
                            defaultMessage={`Based on {trustpilotReviews} reviews • {trustpilotRating} out of 5`}
                            values={{
                                trustpilotReviews: (
                                    <span>{trustpilotReviews}</span>
                                ),
                                trustpilotRating: (
                                    <span>{trustpilotRating}</span>
                                )
                            }}
                        />
                    </p>
                </a>
                {partnershipLogo && (
                    <GatsbyImage
                        fluid={partnershipLogo.fluid}
                        className={styles.TrustElement}
                        key={partnershipLogo.id}
                    />
                )}
            </div>
        </section>
    );
};

export default Header;
