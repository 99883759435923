import React from 'react';
import { BasicCircle } from '../../../../../SVGComponents/shapes';
import styles from './shapes.module.scss';

const HowItWorksShapesSection = ({ children }) => {
    return (
        <div className={styles.shapeContainer}>
            <BasicCircle
                color="london75"
                className={styles.BasicLondonCircle}
                size="sm"
            />
            {children}
        </div>
    );
};

export default HowItWorksShapesSection;
